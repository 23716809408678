angular.module('FactoriesModule')
  .factory('SDKTypeFactory', function () {

  var getDisplayName = function(sdkType) {
    var displayName;
    switch (sdkType) {
      case 'legacy':
        displayName = 'Legacy';
        break;
      case 'point':
        displayName = 'LISNR® Point';
        break;
      case 'radius':
        displayName = 'LISNR® Radius®';
        break;
      case 'sda':
        displayName = 'LISNR® SDA';
        break;
      case 'radius3':
        displayName = 'LISNR® Radius® 3.0+';
        break;
      default:
        displayName = 'UNKNOWN';
        break;
    }
    return displayName;
  }

  return {
    getDisplayName: getDisplayName,
  }
});

'use strict';

angular.module('ControllersModule', []);
angular.module('FactoriesModule', []);
angular.module('DirectivesModule', []);

angular.module('portal', ['ControllersModule', 'FactoriesModule', 'DirectivesModule', 'ngCookies', 'ngRoute', 'ngFileUpload', 'angularCancelOnNavigateModule', 'angular-click-outside'])

.config(function ($provide, $locationProvider, $cookiesProvider, $routeProvider, $httpProvider, $compileProvider, $sceDelegateProvider, SECTION_NAMES, SUBSECTION_NAMES) {
  $routeProvider

  //Login
  .when('/login/', {
    templateUrl: 'pages/login/login.html',
    controller: 'LoginController',
    data: {
      nav: false,
      title: 'Login'
    }
  })

  //Forgot Password
  .when('/forgot-password/:token/', {
    templateUrl: 'pages/forgot-password/forgot-password.html',
    controller: 'ForgotPasswordController',
    data: {
      nav: false,
      title: 'Forgot Password'
    }
  })

  //Notifications Page
  .when('/notifications/', {
    templateUrl: 'pages/mobile-notifications/mobile-notifications.html',
    controller: 'MobileNotificationsController',
    data: {
      title: 'Notifications'
    }
  })

  //LISNR® Basics
  .when('/help-center/', {
    templateUrl: 'pages/help-center/help-center.html',
    data: {
      title: 'LISNR® Basics',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/', {
    templateUrl: 'pages/help-center/getting-started/getting-started.html',
    data: {
      title: 'LISNR® Basics - Getting Started',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/how-to-use-the-tone-creator/', {
    templateUrl: 'pages/help-center/getting-started/how-to-use-the-tone-creator/how-to-use-the-tone-creator.html',
    data: {
      title: 'LISNR® Basics - Getting Started - How To Use the Tone Creator',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/how-to-create-an-app/', {
    templateUrl: 'pages/help-center/getting-started/how-to-create-an-app/how-to-create-an-app.html',
    data: {
      title: 'LISNR® Basics - Getting Started - How To Create An App',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/how-to-create-an-sdk-token/', {
    templateUrl: 'pages/help-center/getting-started/how-to-create-an-sdk-token/how-to-create-an-sdk-token.html',
    data: {
      title: 'LISNR® Basics - Getting Started - How To Create An SDK Token',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/how-to-add-and-manage-users/', {
    templateUrl: 'pages/help-center/getting-started/how-to-add-and-manage-users/how-to-add-and-manage-users.html',
    data: {
      title: 'LISNR® Basics - Getting Started - How To Add And Manage Users',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/lisnr-tone-profiles/', {
    controller: 'ToneProfilesController',
    templateUrl: 'pages/help-center/getting-started/lisnr-tone-profiles/lisnr-tone-profiles.html',
    data: {
      title: 'LISNR® Basics - Getting Started - LISNR® Tone Profiles',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })
  .when('/help-center/getting-started/using-electronic-invoicing/', {
    templateUrl: 'pages/help-center/getting-started/using-electronic-invoicing/using-electronic-invoicing.html',
    data: {
      title: 'LISNR® Basics - Getting Started - Using Electronic Invoicing',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.HELP_CENTER
    }
  })

  //Developer Resources
  .when('/developer-resources/', {
    controller: 'DeveloperResourcesPageController',
    templateUrl: 'pages/developer-resources/developer-resources.html',
    data: {
      title: 'Developer Resources',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/api/', {
    templateUrl: 'pages/developer-resources/api/api.html',
    controller: 'ApiController',
    data: {
      title: 'Developer Resources - API Docs & API Token',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/android/', {
    templateUrl: 'pages/developer-resources/android/android.html',
    controller: 'AndroidController',
    data: {
      title: 'Developer Resources - Android SDK, Support Docs, & Sample projects',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/android/android-key-hashes', {
    templateUrl: 'pages/developer-resources/android/android-key-hashes.html',
    controller: 'AndroidKeyHashesController',
    data: {
        title: 'Developer Resources - Android SDK, Support Docs, & Sample projects - Android Key Hashes',
        section: SECTION_NAMES.HELP,
        subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when("/developer-resources/android/android-application-id", {
    templateUrl: "pages/developer-resources/android/android-application-id.html",
    controller: 'AndroidApplicationIDController',
    data: {
      title: "Developer Resources - Android SDK, Support Docs, & Sample projects - Android Application ID",
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES,
    },
  })
  .when('/developer-resources/ios/', {
    templateUrl: 'pages/developer-resources/ios/ios.html',
    controller: 'iOSController',
    data: {
      title: 'Developer Resources - iOS SDK, Support Docs, & Sample projects',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/ios/apple-bundle-id', {
    templateUrl: 'pages/developer-resources/ios/apple-bundle-id.html',
    controller: 'AppleBundleIdController',
    data: {
      title: 'Developer Resources - iOS SDK, Support Docs, & Sample projects - Apple Bundle ID',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/windows/', {
    templateUrl: 'pages/developer-resources/windows/windows.html',
    controller: 'WindowsController',
    data: {
      title: 'Developer Resources - Windows SDK, Support Docs, & Sample projects',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/linux/', {
    templateUrl: 'pages/developer-resources/linux/linux.html',
    controller: 'LinuxController',
    data: {
      title: 'Developer Resources - Linux SDK, Support Docs, & Sample projects',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })
  .when('/developer-resources/faq/', {
    templateUrl: 'pages/developer-resources/faq/faq.html',
    data: {
      title: 'Developer Resources - FAQ',
      section: SECTION_NAMES.HELP,
      subsection: SUBSECTION_NAMES.DEVELOPER_RESOURCES
    }
  })

  //Account Management
  .when('/account/', {
    templateUrl: 'pages/account-management/account-info.html',
    controller: 'AccountInfoController',
    data: {
      title: 'Account Management - Account Info',
      section: SECTION_NAMES.ACCOUNT,
      subsection: SUBSECTION_NAMES.ACCOUNT_INFO
    }
  })
  .when('/account/users/', {
    templateUrl: 'pages/account-management/manage-users.html',
    controller: 'ManageUsersController',
    data: {
      title: 'Account Management - Manage Users',
      section: SECTION_NAMES.ACCOUNT,
      subsection: SUBSECTION_NAMES.MANAGE_USERS
    }
  })
  .when('/account/users/create', {
    templateUrl: 'pages/account-management/add-user.html',
    controller: 'AddUserController',
    data: {
      title: 'Account - Add User',
      section: SECTION_NAMES.ACCOUNT,
      subsection: SUBSECTION_NAMES.MANAGE_USERS
    }
  })
  .when('/account/users/user-profile/', {
    templateUrl: 'pages/account-management/user-profile.html',
    controller: 'UserProfileController',
    data: {
      title: 'Account - User Profile & Organization',
      section: SECTION_NAMES.ACCOUNT,
      subsection: SUBSECTION_NAMES.MANAGE_USERS
    }
  })
  .when('/account/users/:id/', {
    templateUrl: 'pages/account-management/manage-user.html',
    controller: 'ManageUserController',
    data: {
      title: 'Account - Manage User',
      section: SECTION_NAMES.ACCOUNT,
      subsection: SUBSECTION_NAMES.MANAGE_USERS
    }
  })

  //Apps Page
  .when('/apps/create', {
    templateUrl: 'pages/apps/apps.html',
    controller: 'AppsController',
    data: {
      title: 'App Management - Create App',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.APP_CREATE
    }
  })

  //App Settings
  .when('/apps/current/', {
    templateUrl: 'pages/apps/app-settings-current.html',
    controller: 'AppSettingsCurrentController',
    data: {}
  })
  .when('/apps/current/api-tokens', {
    templateUrl: 'pages/api-tokens/api-tokens-current.html',
    controller: 'ApiTokensCurrentController',
    data: {}
  })
  .when('/apps/current/sdk-tokens', {
    templateUrl: 'pages/sdk-tokens/sdk-tokens-current.html',
    controller: 'SdkTokensCurrentController',
    data: {}
  })
  .when('/apps/:appid/', {
    templateUrl: 'pages/app-settings/app-settings.html',
    controller: 'AppSettingsController',
    data: {
      title: 'App Management - App Settings',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.APP_SETTINGS
    }
  })
  //Tone Creator
  .when('/tone-creator/', {
    templateUrl: 'pages/tone-creator/tone-creator.html',
    controller: 'ToneCreatorController',
    reloadOnSearch: false,
    data: {
      title: 'Tone Creator',
      section: SECTION_NAMES.TONE_CREATOR,
      subsection: ''
    }
  })

  // Tone Speed Calculator
  .when('/tone-speed-calculator/', {
    templateUrl: 'pages/tone-speed-calculator/tone-speed-calculator.html',
    controller: 'ToneSpeedCalculatorController',
    data: {
      title: 'LISNR® Basics - Overview - Tone Speed Calculator',
      section: SECTION_NAMES.TONE_SPEED_CALCULATOR,
      subsection: ''
    }
  })
  //API Tokens
  .when('/apps/:appid/api-tokens/', {
    templateUrl: 'pages/api-tokens/api-tokens.html',
    controller: 'APITokensController',
    data: {
      title: 'App Management - API Tokens',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.API_TOKENS
    }
  })

  //SDK Tokens
  .when('/apps/:appid/sdk-tokens/', {
    templateUrl: 'pages/sdk-tokens/sdk-tokens.html',
    controller: 'SDKTokensController',
    data: {
      title: 'App Management - SDK Tokens',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.SDK_TOKENS
    }
  })

  .when('/apps/:appid/sdk-tokens/create', {
    templateUrl: 'pages/sdk-tokens/create-sdk-token/create-sdk-token.html',
    controller: 'CreateSDKTokenController',
    data: {
      title: 'App Management - SDK Tokens - Create an SDK Token',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.SDK_TOKENS
    }
  })

  //Analytics Pages
  .when('/apps/:appid/analytics/', {
    templateUrl: 'pages/analytics/analytics.html',
    controller: 'AnalyticsController',
    data: {
      title: 'Analytics',
      section: SECTION_NAMES.APPS,
      subsection: SUBSECTION_NAMES.ANALYTICS
    }
  })

  //Billing Pages
  .when('/billing/', {
    templateUrl: 'pages/billing/billing.html',
    controller: 'BillingController',
    data: {
      title: 'Billing',
      section: SECTION_NAMES.BILLING,
      subsection: SUBSECTION_NAMES.BILLING
    }
  })

  //Error Pages
  .when('/404/', {
    templateUrl: 'pages/404/404.html',
    data: {
      title: '404'
    }
  })
  .when('/', {})
  .otherwise({
    redirectTo: '/404/'
  })

  //Old URL Redirects
  .when('/resource-center/', {
    redirectTo: '/help-center/'
  })
  .when('/resource-center/:anything', {
    redirectTo: function (routeParams, path, search) {
        return "/help-center/";
      }
  })
  .when('/app-settings/', {
    redirectTo: function() {return '/';}
  })
  .when('/apps/', {
    redirectTo: '/apps/create'
  })
  .when('/manage-users/', {
    redirectTo: '/account/users/'
  })
  .when('/manage-users/:id/', {
    redirectTo: '/account/users/:id/'
  })
  .when('/add-user/', {
    redirectTo: '/account/users/create/'
  })
  .when('/user-profile/', {
    redirectTo: '/account/users/user-profile/'
  })
  .when('/analytics/', {
    redirectTo: function() {return '/';}
  })
  .when('/create-tone/', {
    redirectTo: function() {return '/';}
  });

  //disallow accessing scope through the dom. can speed up production environments
  $compileProvider.debugInfoEnabled = false;
  //uses html5 history api so no need for '#' in urls. note: you must redirect all calls to index via your server (.htaccess on apache... something else on nginx)
  $locationProvider.html5Mode(true);
  //intercept all ajax calls to inject auth headers and catch response errors
  $httpProvider.interceptors.push('HTTPFactory');

  //configure cookies to expire after 7 days
  var today = new Date();
  var sevenDaysFromNow = new Date(today);
  sevenDaysFromNow.setDate(today.getDate()+7);
  $cookiesProvider.defaults.path = '/';
  $cookiesProvider.defaults.expires = sevenDaysFromNow;

  //need to allow loading of urls from other domains
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'https://*.amazonaws.com/**',
    'https://*.s3.amazonaws.com/**',
    'https://s3.*.amazonaws.com/**'
  ]);

  //raven instance setup
  var sentryOptions = {
    logger: 'javascript',
    whitelistUrls: ['*localhost*', 'portal-dev.*', 'portal-staging.*', 'portal.lisnr.*'],
    tags: {
      commit: commit,
      environment: environment,
      builddate: buildDate
    },
    collectWindowErrors: true
  };

  Raven.debug = false;
  if(!Raven.isSetup()) {
    Raven.config(sentryEndpoint, sentryOptions).install();
  }

  //send exceptions to raven for reporting
  $provide.decorator("$exceptionHandler", function ($delegate) {
    return function (exception, cause) {
      $delegate(exception, cause);
      Raven.captureMessage(exception, {
        'extra': {stack: exception.stack, cause: cause}
      });
    };
  });
})

.run(function ($timeout, $route, AuthenticationFactory, $rootScope, $location, DialogFactory, LoaderDialogFactory) {
  $rootScope.user = [];
  $rootScope.user.currentapp = null;
  $rootScope.dialog = [];
  $rootScope.dialog.open = false;
  $rootScope.appDeleteDialog = [];
  $rootScope.appDeleteDialog.open = false;
  $rootScope.authDialog = [];
  $rootScope.authDialog.open = false;
  $rootScope.loaderDialog = [];
  $rootScope.loaderDialog.open = false;
  $rootScope.apps = [];
  $rootScope.redirectUnauthed = false;
  $rootScope.settingAppFromUrl = false;

  //prevent invalid hostnames on URIs
  URI.preventInvalidHostname = true;

  //this gets set from the environment variables
  $rootScope.authURL = new URI(apiBaseURL + "v2/auth/");
  $rootScope.apiBase = new URI(apiBaseURL + "api/v1/");
  $rootScope.apiBaseV2 = new URI(apiBaseURL + "v2/");

  AuthenticationFactory.checkCommit();

  //authenticate user. if not logged in log your ass out, and redirect to the login page.
  //dont do this on the forgot password page though
  if($location.$$path.substr(0,17) !== '/forgot-password/') {
    //setting variables for redirecting to originally requested page after login if not logged in
    $rootScope.redirectUnauthed = true;
    $rootScope.redirectUnauthedURL = $location.$$url;
  }

  $rootScope.$on('$routeChangeStart', function (event, next, current) {
    var currentPath;
    if(current) {
      currentPath = buildPathFromRoute(current);
    }
    //ad block detector - https://stackoverflow.com/questions/18180598/ad-blocker-detection-aka-adblock-plus/24111206
    if(next.$$route.templateUrl === 'pages/analytics/analytics.html') {
      var xmlhttp = new XMLHttpRequest()
      xmlhttp.onreadystatechange = function() {
        if(xmlhttp.readyState == XMLHttpRequest.DONE){
          if(xmlhttp.status == 0) {
            $rootScope.adBlockEnabled = true;
          }else{
            $rootScope.adBlockEnabled = false;
          }

          if($rootScope.adBlockEnabled && next.$$route.templateUrl === 'pages/analytics/analytics.html') {
            DialogFactory.openDialog(null, "You have an ad blocker enabled and this page was unable to load. Please disable your ad blocker to view this page.", false, closeAdBlockDialog);
          }
        }
      }
      xmlhttp.open("GET", "/pages/analytics/analytics.html", true)
      xmlhttp.send();
    }

    function buildPathFromRoute(routeObj) {
      var path = routeObj.$$route.originalPath;
      for (var property in routeObj.pathParams) {
        if (routeObj.pathParams.hasOwnProperty(property)) {
          var regEx = new RegExp(":" + property, "gi");
          path = path.replace(regEx, routeObj.pathParams[property].toString());
        }
      }
      return path;
    }

    function closeAdBlockDialog() {
      $rootScope.settingAppFromUrl = false;
      DialogFactory.closeDialog();
      if(current) {
        $location.path(currentPath);
      } else {
        AuthenticationFactory.homepageRedirect();
      }
    }

    //setting title, section, and nav bar shown
    if(next.$$route && next.$$route.data) {
      $rootScope.nav = (typeof next.$$route.data.nav === 'undefined') ? true : false;
      $rootScope.title = next.$$route.data.title;
      $rootScope.section = next.$$route.data.section;
      $rootScope.subsection = next.$$route.data.subsection;
    }

    //check if you're logged in on route changes
    //dont do this on the forgot password page though
    if(next.$$route) {
      if(next.$$route.originalPath.substr(0,17) !== '/forgot-password/') {
        if(AuthenticationFactory.checkLoggedIn()) {
          //dont do this on redirects
          if(next.$$route) {
            //if you go to the login page when you're already logged in, go to the home page
            if(next.$$route.originalPath === '/login/') {
              AuthenticationFactory.homepageRedirect();
              return;
            }

            //when you try to go to the home page (aka '/') do a homepage redirect
            if(next.$$route.originalPath === '/') {
              AuthenticationFactory.homepageRedirect();
              return;
            }

            AuthenticationFactory.checkCommit();
          }
        }
      }
    }
  });

  //the following code detect if user clicks browser back button
  $rootScope.$on('$locationChangeSuccess', function() {
    $rootScope.settingAppFromUrl = false;
    $rootScope.actualLocation = $location.path();
    if($rootScope.apps.length > 0) {
      if($location.path().slice(6,12) !== 'create' && $location.path().slice(6,13) !== 'current') {
          if($location.path().slice(0,6) === '/apps/') {
            var appExists = false;
            $rootScope.apps.forEach(function(app) {
              if($location.path().slice(6,42) == app.id) {
                AuthenticationFactory.setCurrentApp(JSON.stringify(app));
                appExists = true;
              }
            });

            if(!appExists) {
              DialogFactory.openDialog(null, "Application doesn't exist or you dont have access. Redirecting to home page.", false, closeDialog);
            }
          }
        }
      }
  });

  $rootScope.$watch(function () {return $location.path()}, function (newLocation, oldLocation) {
    if($rootScope.actualLocation === newLocation) {
      //closes any open dialogs or loader dialogs if the users clicks the browser back button
      DialogFactory.closeDialog();
      LoaderDialogFactory.closeLoaderDialog();
    }
  });

  $timeout(function() {
    if($rootScope.user.currentapp !== null) {
      if($location.path().slice(0,6) === '/apps/') {
        if($location.path().slice(6,12) !== 'create' && $location.path().slice(6,13) !== 'current') {
          if($rootScope.user.currentapp.id !== $location.path().slice(6,42)) {
            setAppFromUrl();
          }
        }
      }
    } else if($location.path().slice(0,6) === '/apps/') {
      if($location.path().slice(6,12) !== 'create' && $location.path().slice(6,13) !== 'current') {
        setAppFromUrl();
      }
    }
  });

  function setAppFromUrl() {
    $rootScope.settingAppFromUrl = true;
  }

  $rootScope.$watch('apps', function () {
    var appExists = false;
    if($rootScope.settingAppFromUrl) {
      $rootScope.apps.forEach(function(app) {
        if($location.path().slice(6,42) == app.id) {
          AuthenticationFactory.setCurrentApp(JSON.stringify(app));
          appExists = true;
        }
      });

      if(!appExists) {
        DialogFactory.openDialog(null, "Application doesn't exist or you dont have access. Redirecting to home page.", false, closeDialog);
      } else {
        $rootScope.settingAppFromUrl = false;
        $route.reload();
      }

      if($route.current.$$route.originalPath === '/apps/:appid/analytics/') {
        DialogFactory.openDialog(null, "Application is in offline mode. Redirecting to home page.", false, closeDialog);
      }
    }
  })

  function closeDialog() {
    $rootScope.settingAppFromUrl = false;
    DialogFactory.closeDialog();
    AuthenticationFactory.homepageRedirect();
  }
})

.constant('SECTION_NAMES', {
  HELP: 'help',
  ACCOUNT: 'account',
  APPS: 'apps',
  TONE_CREATOR: 'tone-creator',
  TONE_SPEED_CALCULATOR: 'tone-speed-calculator',
  BILLING: 'billing'
})

.constant('SUBSECTION_NAMES', {
  HELP_CENTER: 'help-center',
  DEVELOPER_RESOURCES: 'developer-resources',
  TONE_SPEED_CALCULATOR: 'tone-speed-calculator',
  MANAGE_USERS: 'manage-users',
  APP_CREATE: 'app-create',
  APP_SETTINGS: 'app-settings',
  API_TOKENS: 'api-tokens',
  SDK_TOKENS: 'sdk-tokens',
  ANALYTICS: 'analytics',
  ACCOUNT_INFO: 'account-info',
  BILLING: 'billing'
})
